:root {
  --primary: #006d91;
  --primary-light: #0091c1;
  --primary-lighter: #edfaff;
  --primary-dark: #005775;
  --secondary: #f3b61f;
  --secondary-light: #f7d070;
  --secondary-dark: #c98200;
  --background: #fff;
  --text: #434343;
  --boxbackground: #fffffe;
  --white: #ffffff;
  --warning: #910041;
  --headercolor: #006d91;
  --headerbutton: #fff;
  --loginlogo: white;
  --boxshadowcolor: #999999;
  --boxshadow: 0 2px 10px 0 var(--boxshadowcolor);
}
