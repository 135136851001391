.root {
    flex-grow: 1;
}
.grow {
    flex-grow: 1;
}
.paper {
    text-align: center;
    width: auto;
}
.recurrenceContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-width: 2px;
    border-radius: 5px;
    border-style: solid;
    position: relative;
}
.dayPickersContainer {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
}
.removeWrapper {
    right: 0;
    left: auto;
    position: absolute;
}
.recurrencePaper {
    text-align: center;
}

@media (max-width: 800px) {
    .recurrenceContainer {
        flex-direction: column;
    }
    .dayPickersContainer {
        flex-direction: column;
        flex-wrap: wrap;
    }
    .timePickers {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .button-group {
        width: 100%;
    }
    .button-group button {
        font-size: 0.8rem;
        height: 2.5rem;
        width: auto;
        flex-grow: 1;
        padding: 1rem 2rem;
    }
    .dayChipsContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    button {
        font-size: 0.65rem;
        height: 2rem;
        flex-grow: 1;
        padding: 2rem 4rem;
    }
    #all-day-checkbox span {
        font-size: 1.1rem;
    }
}

@media (max-width: 600px) {
    .button-group button {
        height: 2.7rem;
    }
    .timePickers {
        flex-direction: row;
        justify-content: space-between;
    }
}

@media (max-width: 500px) {
    .recurrenceContainer {
        flex-direction: column;
        align-items: center;
    }
    .removeWrapper {
        position: static;
        width: 80%;
        left: 0;
        right: 0;
        padding-right: 0 !important;
    }
    .removeWrapper button {
        width: 100%;
    }
    .dayChipsContainer {
        max-height: 130px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        align-content: space-evenly;
    }
    .dayPickersContainer {
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
    }
    .timePickers {
        flex-direction: column;
    }
    .button-group button {
        font-size: 0.7rem;
        height: 4em;
        flex-grow: 1;
        padding: 1em 2em;
    }
    button {
        font-size: 0.5rem;
        height: 2rem;
        padding: 2rem 4rem;
    }
    .paper, .recurrenceContainer {
        padding-left: 0.6rem !important;
        padding-right: 0.6rem !important;
    }
    .recurrenceGridContainer {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }
}

@media (max-width: 320px) {
    .button-group button {
        font-size: 0.55em;
    }
    .button-group {
        display: flex;
        flex-direction: column;
        box-shadow: none !important;
    }
    .button-group button {
        font-size: 0.8rem;
        height: 2.8rem;
        flex-grow: 1;
        padding: 1rem 2rem;
        margin-top: 0.1rem !important;
        border-radius: 0.8rem !important;
    }
    button {
        font-size: 0.5rem;
        height: 2rem;
        padding: 2rem 4rem;
    }
    .recurrenceContainer {
        padding-left: 0.7rem !important;
        padding-right: 0.7rem !important;
    }
    .dayPickersContainer {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .dayChipsContainer span {
        padding-right: 0.7rem;
        padding-left: 0.7rem;
    }
}

@media (max-width: 300px) {
    .paper, .recurrenceContainer {
        padding-left: 0.3rem !important;
        padding-right: 0.3rem !important;
    }
    .recurrenceGridContainer {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
    .dayChipsContainer span {
        padding-right: 0.4rem;
        padding-left: 0.4rem;
    }
}