.timeslot .paper {
    border-top: 5px solid var(--primary-dark);
}

.new-timeslot .paper{
    border: 1px dashed var(--primary-light);
}

.new-timeslot-paragraph {
    font-size: 20px !important;
    text-transform: capitalize;
}

.new-timeslot-button button {
    background-color: var(--primary-light);
    color: var(--white);
    border-radius: 50%;
    height: 4rem;
    width: 4rem;
    margin-top: 1.2rem;
}

@media (max-width: 800px) {
    .new-timeslot {
        display: none;
    }
    .new-timeslot-button {
        display: block;
    }
}