.extra-filter-options-container {
    display: flex;
    justify-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.extra-filter-options-container > div{
    width: 100%;
    margin: 0.5px;
}

.extra-filter-accordion {
    width: 100%;
    margin: 0.5rem 0;
    border: 3px solid #e0e0e0;
}

@media (max-width: 959px) {
    .lg-xl-source-selector, .lg-xl-tags-selector,
    .lg-xl-severity-selector, .lg-xl-filter-selector,
    .lg-xl-more-settings-item, .lg-xl-open-state-switch,
    .lg-xl-acked-state-switch {
        display: none !important;
    }
    #filter-select {
        padding-right: 0.3rem;
    }
    .extra-filter-options-container {
        padding: 1rem !important;
    }
    .incidents-filter-toolbar-root {
        padding: 0.5rem !important;
        box-sizing: border-box;
    }
    #filter-select-source-helper-text, #filter-select-tags-helper-text,
    .acked-state-switch > p, .open-state-switch > p {
        display: none;
    }
    .acked-state-switch, .open-state-switch{
        margin: 0.5rem 0.5rem !important;
        width: 47%;
    }
    .acked-state-switch button, .open-state-switch button{
        flex-grow: 1;
        width: calc(100%/3);
        height: 2.3rem;
    }
    .extra-filter-accordion {
        margin: 0.5rem 0.5rem !important;
    }
    .sm-more-settings-item svg{
        display:block; position: absolute;
        border: none;
        overflow: hidden;
    }
}

@media (max-width: 599px) {
    .acked-state-switch, .open-state-switch{
        width: 100%;
    }
    .acked-state-switch button, .open-state-switch button{
        height: 2.2rem;
    }
}